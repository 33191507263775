<!-- Mobile -->
<div class="mobile-title" (click)="onExpand()">
  <ui-breadcrumbs [items]="mobileBreadcrumbs"></ui-breadcrumbs>
<!--  {{currentRouteTitle || currentItemTitle}}-->
  <!--<span></span>
  <div [ngClass]="collapsed ? 'ui-icon-plus' : 'ui-icon-minus'" [class.flip]="!collapsed"></div>-->
</div>

<!-- Collapse button -->
<button class="btn-gray btn-collapse" [class.opened]="!isCollapsed" (click)="onExpand()">
  <div class="ui-icon-arrow-down"></div>
</button>

<!-- Rows -->
@for (item of items(); track item.label) {
  <ng-container
    [ngTemplateOutlet]="row"
    [ngTemplateOutletContext]="{item, level: 0}"
    >
  </ng-container>
}

<ng-template #row let-item="item" let-level="level">
  <!-- Row with [routerLink] -->
  @if (item.url) {
    <a
      class="row"
      [class.with-image]="item.image"
      [class.separator-line]="item.separator"
      [routerLink]="item.url"
      [routerLinkActive]="item.url ? 'active' : ''"
      [routerLinkActiveOptions]="{exact: item.exact === undefined ? (!!item.children?.length) : (item.exact)}"
      [ngClass]="'level' + level + ' ' + (('style-'+item.style) || '')"
      [target]="item.target || (item.urlExt ? '_blank' : undefined)"
      (click)="onRowClick(item, $event)"
      rel="noopener noreferrer"
      >
      <ng-container
        [ngTemplateOutlet]="rowContent"
        [ngTemplateOutletContext]="{level: level, item: item}"
      ></ng-container>
    </a>
  } @else {
    <!-- Row without [routerLink] but can bind href for external links -->
    @if (!item.url) {
      <a
        class="row"
        [class.active2]="item.childActive"
        [class.separator-line]="item.separator"
        [routerLinkActive]="item.url ? 'active' : ''"
        [attr.href]="item.urlExt"
        [target]="item.target || (item.urlExt ? '_blank' : undefined)"
        [ngClass]="'level' + level + ' ' + (('style-'+item.style) || '')"
        (click)="onRowClick(item, $event)"
        rel="noopener noreferrer"
        >
        <ng-container
          [ngTemplateOutlet]="rowContent"
          [ngTemplateOutletContext]="{level: level, item: item}"
        ></ng-container>
      </a>
    }
  }

  <!-- Row template -->
  <ng-template #rowContent>
    @if (item.icon) {
      <img [src]="item.icon" class="img-icon">
    }
    @if (item.image) {
      <img [src]="item.image">
    }
    {{item.label}}
    @if (item.counter) {
      <span class="counter">{{item.counter}}</span>
    }
    @if (item.children?.length) {
      <div class="ui-icon-arrow-down icon-expand" [class.expanded]="item.expanded"></div>
    }
  </ng-template>

  @if (item.children?.length && item.expanded) {
    <div class="child-rows" [@enterAnimation] [@.disabled]="isFirstInit">
      @for (child of item.children; track child.label) {
        <ng-container
          [ngTemplateOutlet]="row"
          [ngTemplateOutletContext]="{item: child, level: level + 1}"
        ></ng-container>
      }
    </div>
  }
</ng-template>
