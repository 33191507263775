import {
  ChangeDetectorRef,
  Directive,
  inject,
  InjectionToken,
  Injector,
  model,
  OnInit,
  runInInjectionContext,
  signal,
  viewChild
} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationStart, ResolveFn, Router} from '@angular/router';
import {GridComponent, GridSortingOrder, IGridConfig, IPagingData, ISortEvent} from '../components/grid/grid';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ProgressService} from '../services/progress.service';
import {FilterComponent, IFilter, IFilterDesc, IStoredFilter} from '../components/filter/filter.component';
import {filter} from 'rxjs';

export const GRID_PAGE_VALUE = new InjectionToken<number>('GRID_PAGE_VALUE');

export interface IPageWithGridModel<T> {
  data: IPagingData<T>;
  search: string;
  sorting: string;
  page?: number;
  filters?: IFilter;
  filterValues?: IStoredFilter[];
}

export function getParentDictionaries<T>(route: ActivatedRouteSnapshot) {
  return route.parent?.data.dictionaries as T;
}

export function getPageGridModel<T>(route: ActivatedRouteSnapshot, filters?: IFilter) {
  const search = route.queryParams.search ?? '';
  const sorting = route.queryParams.sorting ?? '';
  const flt = route.queryParams.filters ?? '';
  let page = route.queryParams.page ?? 1;
  let filterValues: IStoredFilter[] | undefined;
  if (flt) {
    try {
      filterValues = JSON.parse(flt);
      FilterComponent.loadFilterValues(filters, filterValues);
    } catch {
    }
  }
  try {
    page = inject(GRID_PAGE_VALUE);
  } catch {
  }
  return {
    search,
    sorting,
    page,
    filterValues,
    filters
  } as IPageWithGridModel<T>;
}

export function runWithPageValue<ReturnT>(injector: Injector, page: number, fn: () => ReturnT) {
  const contextInjector = Injector.create({
    providers: [
      {provide: GRID_PAGE_VALUE, useValue: page}
    ],
    parent: injector
  });

  return runInInjectionContext(contextInjector, fn);
}

@Directive()
export class PageWithGridComponent implements OnInit {
  readonly grid = viewChild(GridComponent);
  model!: IPageWithGridModel<any>;
  config: IGridConfig = {
    sortingField: 'prod',
    sortingOrder: 'asc'
  };
  isPopupFilters = false;
  isEdit = signal(false);
  protected resolver?: ResolveFn<IPageWithGridModel<any>>;
  protected router = inject(Router);
  protected route = inject(ActivatedRoute);
  protected injector = inject(Injector);
  protected cdr = inject(ChangeDetectorRef);
  protected ps = inject(ProgressService);
  protected data$ = this.route.data.pipe(takeUntilDestroyed());
  protected routerEvents$ = this.router.events.pipe(takeUntilDestroyed());
  protected updateAfterDataReceived = true;
  protected lastNavigationID = -1;

  protected filters = model<IFilter>([]);
  private allowFilterUpdate = true;

  private subscribeForFilters() {
    // Receive filters only first time page loads
    // to prevent flickering during filtering and
    // running new data request
    this.data$.subscribe(d => {
      if (this.allowFilterUpdate) {
        this.filters.set(d.model.filters);
      }
      this.allowFilterUpdate = false;
    });

    // When user goes back we need to update filters to previous state
    this.routerEvents$.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.allowFilterUpdate = true;
      }
    });
  }

  ngOnInit() {
    const oldUpdate = this.updateAfterDataReceived;
    this.updateAfterDataReceived = false;

    this.subscribeForFilters();


    // TODO: Remove custom state parameters
   /* this.routerEvents$.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation?.extras?.state?.lastNavigationID !== undefined) {
        navigation.extras.state.lastNavigationID = undefined;
      }
      if (navigation?.extras?.state?.isRefresh !== undefined) {
        navigation.extras.state.isRefresh = undefined;
      }
    });*/

    this.data$.subscribe(d => {
      if (!this.isLastNavigation()) {
        return;
      }
      this.model = d.model;
      if (this.model.sorting) {
        this.config.sortingField = this.model.sorting.split('.')[0];
        this.config.sortingOrder = this.model.sorting.split('.')[1] as GridSortingOrder;
      }
      if (this.updateAfterDataReceived) {
        this.cdr.detectChanges();
      }
    });
    this.updateAfterDataReceived = oldUpdate;
  }

  async loadPage(page: number) {
    const s = this.route.snapshot;
    let res: IPageWithGridModel<any>;
    this.ps.show();
    try {
      res = await runWithPageValue(this.injector, page, () => {
        if (!this.resolver) {
          return;
        }
        return this.resolver(s, this.router.routerState.snapshot);
      }) as IPageWithGridModel<any>;
    } finally {
      this.ps.hide();
    }
    if (res) {
      this.model.data = GridComponent.appendPagingData(this.model.data, res.data);
      this.cdr.detectChanges();
    }
  }

  async refreshData() {
    return this.loadPage(1);
  }

  onSorting(e: ISortEvent) {
    this.model.sorting = e.field + '.' + e.order;
    this.refresh();
  }

  onSearchHandler(term: string) {
    this.model.search = term;
    this.refresh();
  }

  onSearch(term: string) {
    this.isPopupFilters = false;
    this.onSearchHandler(term);
  }

  onFilter(f?: IFilterDesc) {
    if (f) {
      this.filters.update(f => [...f]);
    }
    this.refresh();
  }

  protected refresh() {
    let filters: IStoredFilter[] = [];
    if (this.filters()?.length) {
      filters = FilterComponent.getStoredFilters(this.filters());
    }
    /*if (additionalFilters) {
      filters = filters.concat(Object.entries(additionalFilters).map(([id, value]) => ({id, value})));
    }*/
    this.lastNavigationID++;
    void this.router.navigate([], {
      queryParams: {
        search: this.model.search,
        sorting: this.model.sorting,
        filters: filters?.length ? JSON.stringify(filters) : undefined
      },
      state: {
        lastNavigationID: this.lastNavigationID,
        //isRefresh: true
      }
    });
  }

  hideFilters() {
    this.isPopupFilters = false;
    this.cdr.detectChanges();
  }

  isAnyFilter() {
    return !!this.filters()?.some(flt => flt.value);
  }

  toggleEditMode() {
    this.isEdit.update(e => !e);
  }

  protected isLastNavigation() {
    if (this.lastNavigationID === -1) {
      return true;
    }
    const navigation = this.router.getCurrentNavigation();
    const navId = navigation?.extras?.state?.lastNavigationID;
    return navId === this.lastNavigationID;
  }
}
